import { ExportModal, ExportModalProps } from './components/ExportModal';
import { DownloadButton } from './DownloadButton';

export type DownloadParams = {
    setOpen: () => void;
    isDisabled?: boolean;
} & ExportModalProps;

export function Download({
    isDisabled = false,
    isOpen,
    isShowCloseIcon,
    length,
    onApplyFilterClick,
    onDownloadClick,
    onProceedExportClick,
    onTryAgainClick,
    setClose,
    setOpen,
    state,
}: DownloadParams) {
    return (
        <>
            <DownloadButton onClick={setOpen} isDisabled={isDisabled} />

            <ExportModal
                length={length}
                isOpen={isOpen}
                onApplyFilterClick={onApplyFilterClick}
                setClose={setClose}
                isShowCloseIcon={isShowCloseIcon}
                state={state}
                onDownloadClick={onDownloadClick}
                onProceedExportClick={onProceedExportClick}
                onTryAgainClick={onTryAgainClick}
            />
        </>
    );
}
