import { HelmetProvider } from 'react-helmet-async';

import { Head } from '../Head';

interface HelmetProps {
    title: string;
}

export function Helmet({ title }: HelmetProps) {
    return (
        <HelmetProvider>
            <Head title={title} />
        </HelmetProvider>
    );
}
