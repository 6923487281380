import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { IconButton } from '@mui/material';

type ExpandRowProps = {
    onClick: () => void;
    isOpen: boolean;
    testId?: string;
};

export function ExpandRow({ isOpen, onClick, testId = 'expandRow' }: ExpandRowProps) {
    return (
        <IconButton aria-label="expand row" size="small" onClick={onClick} data-testid={testId}>
            {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
    );
}
