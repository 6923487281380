import { useState } from 'react';

type ShowNotificationParams = {
    title: string;
    description?: string;
};

export type UseProvideNotificationsType = {
    showSuccessNotification: (params: ShowNotificationParams) => void;
    showErrorNotification: (params: ShowNotificationParams) => void;
    closeNotification: (_?: React.SyntheticEvent | Event, reason?: string) => void;
    notification: Notification | null;
    isOpen: boolean;
};

export enum NotificationType {
    ERROR = 'error',
    SUCCESS = 'success',
}

type Notification = ShowNotificationParams & {
    type: NotificationType | null;
};

export function useProvideAlertNotifications(): UseProvideNotificationsType {
    const [notification, setNotification] = useState<Notification | null>(null);

    const showSuccessNotification = ({ description = '', title }: ShowNotificationParams) =>
        setNotification({ description, title, type: NotificationType.SUCCESS });

    const showErrorNotification = ({ description = '', title }: ShowNotificationParams) =>
        setNotification({ description, title, type: NotificationType.ERROR });

    const closeNotification = (_?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setNotification(null);
    };

    return {
        closeNotification,
        isOpen: notification !== null,
        notification,
        showErrorNotification,
        showSuccessNotification,
    };
}
