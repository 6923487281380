import { Box, Stack, TextField, FormHelperText } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { isAfter, isValid } from 'date-fns';
import { useState, useEffect } from 'react';

import { getDateFromDaysAgo, minimumDate } from '@dock/common';
import { DATE_TIME_FORMAT, evaluateDateAfterDatePickerInput } from '@dock/react-util';
import { DateFilterParams } from '@dock/types-common';

import { SecondaryButton } from '../Buttons';
import lang from './lang';

export const validateDate = evaluateDateAfterDatePickerInput(isValid);

export type DateFromTo = {
    from?: Date | undefined;
    to?: Date | undefined;
};
export type DateRangePickerProps = DateFromTo & {
    onChange: (dateObj: DateFilterParams) => void;
    isDisabled?: boolean;
    withManualTrigger?: boolean;
};

export function DateRangePicker({
    from,
    isDisabled = false,
    onChange,
    to,
    withManualTrigger = false,
}: DateRangePickerProps) {
    const [endDate, setEndDate] = useState<Date>(to ?? getDateFromDaysAgo(0));
    const [startDate, setStartDate] = useState<Date>(from ?? getDateFromDaysAgo(30));
    const triggerSearch = () => {
        // useCallback?
        if (
            isAfter(startDate, minimumDate) &&
            isAfter(new Date(), endDate) &&
            isAfter(endDate, startDate)
        ) {
            onChange({
                from: startDate.toISOString(),
                to: endDate.toISOString(),
            });
        }
    };

    useEffect(() => {
        if (withManualTrigger) return;
        // !if everything is trigger manually we don't need this:
        // right now, the date filter (cuz the abscent of apply button)
        // is trigger on change of the date input.
        // with the new design, we use a button for trigger this action
        if (
            from !== startDate &&
            to !== endDate &&
            isAfter(startDate, minimumDate) &&
            isAfter(new Date(), endDate) &&
            isAfter(endDate, startDate)
        ) {
            onChange({
                from: startDate.toISOString(),
                to: endDate.toISOString(),
            });
        }
    }, [startDate, endDate]);

    useEffect(() => {
        if (to !== undefined && isAfter(to, endDate)) {
            setEndDate(to);
        }
    }, [to]);

    return (
        <Box sx={{ alignItems: 'end', display: 'flex', flexDirection: 'column' }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack spacing="24px" direction="row">
                    <DateTimePicker
                        label={lang.FROM}
                        disabled={isDisabled}
                        value={startDate}
                        inputFormat={DATE_TIME_FORMAT}
                        onChange={validateDate(setStartDate)}
                        minDate={minimumDate}
                        renderInput={(params) => (
                            <TextField
                                variant="outlined"
                                {...params}
                                inputProps={{
                                    ...params.inputProps,
                                    'data-testid': 'startDateInput',
                                }}
                                error={
                                    isAfter(startDate, endDate) || isAfter(minimumDate, startDate)
                                }
                                fullWidth
                            />
                        )}
                    />
                    <DateTimePicker
                        disabled={isDisabled}
                        label={lang.TO}
                        value={endDate}
                        inputFormat={DATE_TIME_FORMAT}
                        onChange={validateDate(setEndDate)}
                        renderInput={(params) => (
                            <TextField
                                variant="outlined"
                                {...params}
                                inputProps={{
                                    ...params.inputProps,
                                    'data-testid': 'endDateInput',
                                }}
                                error={isAfter(startDate, endDate) || isAfter(endDate, new Date())}
                                fullWidth
                            />
                        )}
                    />
                </Stack>
                {withManualTrigger && (
                    <SecondaryButton
                        onClick={triggerSearch}
                        testId="filterDatesTrigger"
                        isDisabled={isDisabled}
                        sx={{ margin: '24px 0 0' }}
                    >
                        {lang.APPLY}
                    </SecondaryButton>
                )}

                <Stack>
                    {isAfter(startDate, endDate) && (
                        <FormHelperText>{lang.START_DATE_AFTER_END_DATE}</FormHelperText>
                    )}
                    {isAfter(endDate, new Date()) && (
                        <FormHelperText>{lang.END_DATE_AFTER_NOW}</FormHelperText>
                    )}
                    {isAfter(minimumDate, startDate) && (
                        <FormHelperText>{lang.START_DATE_BEFORE_MIN}</FormHelperText>
                    )}
                </Stack>
            </LocalizationProvider>
        </Box>
    );
}
