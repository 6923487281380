import { getDateDaysAgo } from '@dock/common';
import { transformDate, DATE_TIME_FORMAT } from '@dock/react-util';

import lang from './lang';

export const getDateRangeLabel = (daysAgo: number) =>
    `${lang.DROP_DOWN_DATE_RANGE} ${
        daysAgo <= 0
            ? ''
            : `from: ${transformDate(getDateDaysAgo(new Date())(daysAgo), DATE_TIME_FORMAT)}`
    }`;
