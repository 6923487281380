import { TableCell, TableRow } from '@mui/material';
import { useMemo } from 'react';

import { Skeleton } from '../Skeleton';

interface TableSkeletonProps {
    columnsAmount: number;
}

export function TableSkeletonRow({ columnsAmount }: TableSkeletonProps) {
    const loadingElements = useMemo(
        () =>
            Array.from({ length: columnsAmount }).map((_, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <TableCell key={index}>
                    <Skeleton height="25px" width="100%" />
                </TableCell>
            )),
        [columnsAmount]
    );

    return <TableRow data-testid="skeletonRow">{loadingElements}</TableRow>;
}

export function TableSkeleton({
    columns,
    rows,
    shouldShow,
}: {
    rows: number;
    columns: number;
    shouldShow: boolean;
}) {
    if (!shouldShow) {
        return null;
    }

    return (
        <>
            {Array(rows)
                .fill(true)
                .map((_, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <TableSkeletonRow columnsAmount={columns} key={index} />
                ))}
        </>
    );
}
