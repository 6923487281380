import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import { PrimaryButton } from '../Buttons';

type DownloadButtonProps = {
    onClick: () => void;
    isDisabled?: boolean;
};

export function DownloadButton({ isDisabled = false, onClick }: DownloadButtonProps) {
    return (
        <PrimaryButton
            size="small"
            onClick={onClick}
            isDisabled={isDisabled}
            testId="downloadButton"
            sx={{ margin: 'auto 0', maxHeight: '40px' }}
        >
            <FileDownloadOutlinedIcon />
        </PrimaryButton>
    );
}
