import { Box, TextField, SxProps, Theme, TextFieldProps } from '@mui/material';

import { NumberFormatCustom } from '../Filters';
import { HelperText } from './HelperText';
import { MaxLengthCounter } from './MaxLengthCounter';

type InputProps = {
    name: string;
    label: string;
    variant?: 'standard' | 'filled' | 'outlined';
    value: string;
    placeholder?: string;
    onChange: (event: unknown) => void;
    onKeyDown?: () => void;
    inputProps?: TextFieldProps;
    errorMessage?: string | undefined;
    isFullWidth?: boolean;
    disabled?: boolean;
    maxLength?: number | undefined;
    helperText?: string | undefined;
    type?: string | undefined;
    testId?: string | undefined;
    sx?: SxProps<Theme>;
    autoFocus?: boolean;
    startAdornment?: React.ReactNode;
};

export function Input({
    autoFocus = false,
    disabled,
    errorMessage,
    helperText,
    inputProps,
    isFullWidth = false,
    label,
    maxLength,
    name,
    onChange,
    onKeyDown,
    placeholder,
    startAdornment,
    sx = {},
    testId = 'inputTestId',
    type = 'text',
    value,
    variant = 'outlined',
}: InputProps) {
    const helperTextContent = errorMessage || helperText;

    return (
        <Box
            sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                gap: '4px',
                justifyContent: 'center',
                width: '100%',
                ...sx,
            }}
        >
            <TextField
                {...inputProps}
                id={name}
                label={label}
                variant={variant}
                value={value}
                onChange={onChange}
                onKeyDown={onKeyDown}
                error={!!errorMessage}
                fullWidth={isFullWidth}
                disabled={!!disabled}
                autoFocus={autoFocus}
                inputProps={{
                    'data-testid': testId,
                    maxLength,
                }}
                type={type}
                placeholder={placeholder}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                    startAdornment,
                    ...(type === 'amount' && {
                        // TODO: consider to change any to specific type
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        inputComponent: NumberFormatCustom as any,
                    }),
                }}
            />

            {helperTextContent && (
                <HelperText message={helperTextContent} type={errorMessage ? 'error' : 'info'} />
            )}

            {maxLength && <MaxLengthCounter value={value} maxLength={maxLength} />}
        </Box>
    );
}
