import { Box } from '@mui/material';

import { SearchBox } from './SearchBox';
import { OptionType, SingleSelect } from './SingleSelect';

type MultipleChoiceSearchProps<T extends string> = {
    label: string;
    initValue: string;
    handleSearch: (value: string) => void;
    onChange?: ((value: T) => void) | undefined;
    searchBy: T;
    options: OptionType<T>[];
};

export function MultipleChoiceSearch<T extends string>({
    handleSearch,
    initValue,
    label,
    onChange,
    options,
    searchBy,
}: MultipleChoiceSearchProps<T>) {
    return (
        <Box sx={{ display: 'flex' }} data-testid="multipleChoiceSearch">
            {!!onChange && (
                <SingleSelect
                    id="search-option-select"
                    label=""
                    onChange={onChange}
                    defaultOption={searchBy}
                    options={options}
                    inputProps={{
                        sx: {
                            backgroundColor: ({ palette }) => palette.grey[100],
                            borderRadius: '4px 0px 0px 4px',
                        },
                        variant: 'outlined',
                    }}
                />
            )}
            <Box sx={{ width: '100%' }}>
                <SearchBox
                    label={label}
                    setLastSearchValue={handleSearch}
                    initValue={initValue}
                    inputProps={{
                        sx: {
                            borderLeft: 0,
                            borderRadius: '0px 4px 4px 0px',
                        },
                    }}
                />
            </Box>
        </Box>
    );
}
