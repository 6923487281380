import { Box, Typography, CircularProgress } from '@mui/material';

import downloadLang from '../../lang/download';
import { ModalContainer } from './ModalContainer';

type ProgressStateProps = {
    length: number;
};

export function ProgressState({ length }: ProgressStateProps) {
    return (
        <ModalContainer testId="progressState">
            <Box>
                <Typography variant="h2" sx={{ marginBottom: '16px', textAlign: 'center' }}>
                    {downloadLang.PROGRESS_STATE.TITLE}
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '16px', textAlign: 'center' }}>
                    {`${length} ${downloadLang.PROGRESS_STATE.ROWS_EXPORTED}`}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>
            </Box>
        </ModalContainer>
    );
}
