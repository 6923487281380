import { useState, useCallback } from 'react';

export const useModal = () => {
    const [isOpen, setIsOpen] = useState(false);

    const setOpen = useCallback(() => {
        setIsOpen(true);
    }, [setIsOpen]);

    const setClose = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    return { isOpen, setClose, setOpen };
};
