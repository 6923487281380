import { Box, Typography } from '@mui/material';

import { SecondaryButton } from '../../Buttons';
import tablesLang from '../../lang/tables';

export function UnexpectedError() {
    return (
        <Box data-testid="unexpectedError">
            <Typography sx={{ mb: '2px' }}>{tablesLang.ERROR.UNEXPECTED_ERROR_TABLE}</Typography>
            <SecondaryButton
                onClick={() => window.location.reload()}
                sx={{ marginX: 'auto', marginY: '1em', width: '100px' }}
            >
                {tablesLang.ERROR.RELOAD}
            </SecondaryButton>
        </Box>
    );
}
