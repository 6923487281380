import { useState } from 'react';

import { useInterval } from './useInterval';

export const useTimer = (interval = 10000) => {
    const [date, setdate] = useState(new Date());

    useInterval(() => {
        setdate(new Date());
    }, interval);

    return date;
};
