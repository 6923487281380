import { styled } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';

const drawerWidth = 240;

// TODO: Collapsable menu css & logic is ready to be implemented
export const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ open, theme }) => ({
    '& .MuiDrawer-paper': {
        background: theme.palette.grey[100],
        boxSizing: 'border-box',
        height: '100vh',
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        ...(!open && {
            overflowX: 'hidden',
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9),
            },
            width: theme.spacing(7),
        }),
    },
}));
