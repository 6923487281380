import { ColoredAmountText } from '../ColoredAmountText';
import { SimpleRow } from './SimpleRow';

type Props = {
    label: string;
    amount: string | undefined | null;
    currency?: string | undefined | null;
    valueTestId?: string;
};

export function AmountSimpleRow({ amount, currency, label, valueTestId }: Props) {
    return (
        <SimpleRow
            label={label}
            value={<ColoredAmountText amount={amount} currency={currency} />}
            valueTestId={valueTestId}
        />
    );
}
