import { Box } from '@mui/material';

import tablesLang from '../../lang/tables';
import { ForbiddenError } from './ForbiddenError';
import { MessageTemplate } from './MessageTemplate';
import { NotFound } from './NotFound';
import { isError400, isError403, isError404 } from './predicates';
import { UnexpectedError } from './UnexpectedError';

type CustomErrorRowProps = {
    error: unknown;
};

export function CustomErrorRow({ error }: CustomErrorRowProps) {
    let errorMessage;
    switch (true) {
        case isError404(error):
            errorMessage = <NotFound />;
            break;
        case isError400(error):
            errorMessage = (
                <MessageTemplate
                    title={tablesLang.ERROR.BAD_REQUEST}
                    description={tablesLang.ERROR.TRY_SEARCH_AGAIN}
                    testId="badRequest"
                />
            );
            break;
        case isError403(error):
            errorMessage = <ForbiddenError />;
            break;
        default:
            errorMessage = <UnexpectedError />;
            break;
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                minHeight: '450px',
                textAlign: 'center',
            }}
        >
            {errorMessage}
        </Box>
    );
}
