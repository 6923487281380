import { Box } from '@mui/material';

import { PrimaryTextButton, SecondaryButton } from '../Buttons';

type FilterModalButtonsTypes = {
    onResetClick: () => void;
    onApplyClick: () => void;
    resetText: string;
    applyText: string;
    isDisabled?: boolean;
    applyButtonTestId?: string;
    resetButtonTestId?: string;
};

const buttonStyles = () => ({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
});

export function FilterModalButtons({
    applyButtonTestId = 'applyButton',
    applyText,
    isDisabled = false,
    onApplyClick,
    onResetClick,
    resetButtonTestId = 'resetButton',
    resetText,
}: FilterModalButtonsTypes) {
    return (
        <Box sx={buttonStyles}>
            <PrimaryTextButton onClick={onResetClick} testId={resetButtonTestId}>
                {resetText}
            </PrimaryTextButton>
            <SecondaryButton
                onClick={onApplyClick}
                isDisabled={isDisabled}
                testId={applyButtonTestId}
            >
                {applyText}
            </SecondaryButton>
        </Box>
    );
}
