import tablesLang from '../../lang/tables';
import { MessageTemplate } from './MessageTemplate';

export function ForbiddenError() {
    return (
        <MessageTemplate
            title={tablesLang.ERROR.ACCESS_FORBIDDEN}
            description={tablesLang.ERROR.CONTACT_ADMIN_FOR_PERMISSIONS}
            testId="forbidden"
        />
    );
}
