import {
    OpsA2ATransfersPermissionStatus,
    OpsAccountsPermissionStatus,
    OpsActorsPermissionStatus,
    OpsRolesPermissionStatus,
    OpsTenantsPermissionStatus,
    OpsUsersPermissionStatus,
} from '@dock/types-dock-ops';

export enum Currency {
    EUR = 'EUR',
    USD = 'USD',
}

export enum SortOrder {
    ASC = 'asc',
    DESC = 'desc',
}

export type Sorting = {
    field: string;
    direction: SortOrder;
};

export type IdempotencyKey = {
    idempotencyKey: string;
};

export type PayloadWithIdempotencyKey<T> = {
    payload: T;
} & IdempotencyKey;

export type ChangeStatusPayload<P, S> = {
    id: string;
    currentStatus: S;
    payload: P;
} & IdempotencyKey;

export type BaseFilterParams<TSearchBy extends string> = {
    text?: string;
    searchBy?: TSearchBy;
    sortOrder?: SortOrder;
    sortBy?: string;
    minAmount?: string;
    maxAmount?: string;
    from?: string;
    to?: string;
    tenantId?: string;
    recursive?: boolean;
};

export type DateFilterParams = {
    from: string | undefined;
    to: string | undefined;
};

export type ExtractKind<T> = T extends { kind: infer K } ? K : never;

export type ExtractType<T> = T extends { type: infer K } ? K : never;

export type OpsGenericPermissionStatus =
    | OpsAccountsPermissionStatus
    | OpsUsersPermissionStatus
    | OpsRolesPermissionStatus
    | OpsActorsPermissionStatus
    | OpsTenantsPermissionStatus
    | OpsA2ATransfersPermissionStatus;
