import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';

type CopyButtonProps = {
    text: string;
    onCopy: (value: string) => void;
};

export function CopyButton({ onCopy, text }: CopyButtonProps) {
    const clickHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        onCopy(text);
        event.stopPropagation();
    };

    return (
        <IconButton
            data-testid="contentCopyIconButton"
            style={{
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
            }}
            onClick={clickHandler}
        >
            <ContentCopyIcon fontSize="small" />
        </IconButton>
    );
}
