import { TableRow, TableCell, TableHead, Typography } from '@mui/material';

import type { Column } from '../SimpleTable';

type CollapsableElementHeaderProps = {
    columns: Column[] | string[];
};

export function CollapsableElementHeader({ columns }: CollapsableElementHeaderProps) {
    return (
        <TableHead>
            <TableRow>
                {columns.map((column) => {
                    const cellKey = typeof column === 'string' ? column : column?.key;

                    const cellContent = typeof column === 'string' ? column : column?.name;

                    return (
                        <TableCell key={cellKey}>
                            <Typography variant="subtitle1" color="grey.600">
                                {cellContent}
                            </Typography>
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
}
