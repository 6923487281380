import { Box, TableCell, TableRow, Typography } from '@mui/material';
import React from 'react';

import { DASH } from '@dock/common';

import { TypographyWithTooltip } from '../../Typography/TypographyWithTooltip';

type RowsProps = {
    label: string;
    value: React.ReactNode | string;
    valueTestId?: string;
    toolTipText?: string;
};

export function SimpleRow({ label, toolTipText, value, valueTestId = '' }: RowsProps) {
    return (
        <TableRow
            hover
            sx={{
                borderBottom: (theme) => `1px solid ${theme.palette.grey[200]}`,
                display: 'flex',
                flexWrap: 'wrap',
            }}
        >
            <TableCell
                sx={(theme) => ({
                    borderBottom: 'none',
                    [theme.breakpoints.up('xl')]: {
                        width: 270,
                    },
                    [theme.breakpoints.up('xxl')]: {
                        width: 280,
                    },
                    width: 230,
                })}
            >
                <Typography variant="subtitle1" color="grey.600" sx={{ display: 'flex' }}>
                    {label}
                    {toolTipText && (
                        <Box sx={{ marginLeft: '5px' }}>
                            <TypographyWithTooltip text={toolTipText} variant="body2" isWithIcon />
                        </Box>
                    )}
                </Typography>
            </TableCell>
            <TableCell sx={{ borderBottom: 'none' }}>
                {typeof value === 'string' ? (
                    <Typography data-testid={valueTestId}>{value || DASH}</Typography>
                ) : (
                    value || DASH
                )}
            </TableCell>
        </TableRow>
    );
}
