import { SxProps, Theme, Table, TableBody, TableContainer } from '@mui/material';
import React from 'react';

type HeadlessTableProps = {
    children: React.ReactNode;
    tableBodySx?: SxProps<Theme>;
};

export function HeadlessTable({ children, tableBodySx = {} }: HeadlessTableProps) {
    return (
        <TableContainer>
            <Table>
                <TableBody sx={tableBodySx}>{children}</TableBody>
            </Table>
        </TableContainer>
    );
}
