import { Box, SxProps, Theme } from '@mui/material';
import React from 'react';

type WhiteBackgroundWrapperType = {
    children: React.ReactNode;
    sx?: SxProps<Theme>;
    testId?: string;
};

export function WhiteBackgroundWrapper({ children, sx = {}, testId }: WhiteBackgroundWrapperType) {
    return (
        <Box
            sx={{
                backgroundColor: 'background.paper',
                borderRadius: 2,
                paddingTop: '16px',
                ...sx,
            }}
            data-testid={testId}
        >
            {children}
        </Box>
    );
}
