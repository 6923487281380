import { Button, SxProps, Theme } from '@mui/material';
import React from 'react';

type PrimaryButtonProps = {
    children: React.ReactNode;
    onClick?: () => void;
    isDisabled?: boolean;
    testId?: string;
    sx?: SxProps<Theme>;
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
    fullWidth?: boolean;
    size?: 'large' | 'small' | 'medium';
};

export function PrimaryButton({
    children,
    endIcon,
    fullWidth = false,
    isDisabled = false,
    onClick,
    size = 'medium',
    startIcon,
    sx = {},
    testId = 'primaryButton',
}: PrimaryButtonProps) {
    return (
        <Button
            variant="contained"
            onClick={onClick}
            disabled={isDisabled}
            startIcon={startIcon}
            endIcon={endIcon}
            fullWidth={fullWidth}
            data-testid={testId}
            size={size}
            sx={sx}
        >
            {children}
        </Button>
    );
}
