export default {
    CANCEL_EXPORTING: 'Cancel exporting',
    CANCEL_STATE: {
        CONTINUE: 'Continue exporting',
        DESCRIPTION:
            'Are you sure you want to cancel exporting? If you do so, you will lose your current progress.',
    },
    DATA_EXPORT: 'Data export',
    DOWNLOAD: 'Download',
    ERROR: {
        DESCRIPTION:
            'An error occurred during the export. Please try again or contact us if the problem persists.',
        FAILED: 'Export failed',
    },
    INITIAL_STATE: {
        APPLY_FILTERS: 'Apply filters',
        OPTION_1: '1. Exporting a large amount of data may lead to freezing on your device.',
        OPTION_2:
            '2. To avoid this, ensure you apply filters to decrease the number of downloadable rows of data.',
        PROCEED_TO_EXPORT: ' Proceed to data export',
    },
    PROGRESS_STATE: {
        ROWS_EXPORTED: 'rows exported...',
        TITLE: 'Export in progress',
    },
    SUCCESS: {
        COMPLETE: 'Export completed successfully',
        DESCRIPTION: 'You can now download the file to your device.',
    },
    TRY_AGAIN: 'Try again',
};
