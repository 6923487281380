import { Box } from '@mui/material';

type ModalContainerProps = {
    children: React.ReactNode;
    LeftButton?: React.ReactNode | undefined;
    RightButton?: React.ReactNode | undefined;
    testId: string;
};

export function ModalContainer({ children, LeftButton, RightButton, testId }: ModalContainerProps) {
    return (
        <Box data-testid={testId}>
            <Box sx={{ marginBottom: '24px' }}>{children}</Box>
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: LeftButton ? 'space-between' : 'flex-end',
                }}
            >
                {LeftButton}
                {RightButton}
            </Box>
        </Box>
    );
}
