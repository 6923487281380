import downloadLang from '../../lang/download';
import { Modal } from '../../Modal';
import { ErrorState } from './ErrorState';
import { InitialState } from './InitialState';
import { ProgressState } from './ProgressState';
import { SuccessState } from './SuccessState';

export type ModalState = 'initial' | 'progress' | 'success' | 'error';

export type ExportModalProps = {
    state: ModalState;
    onApplyFilterClick: () => void;
    onDownloadClick: () => void;
    onProceedExportClick: () => void;
    onTryAgainClick: () => void;
    isOpen: boolean;
    isShowCloseIcon: boolean;
    setClose: () => void;
    length: number;
};

export function ExportModal({
    isOpen,
    isShowCloseIcon,
    length,
    onApplyFilterClick,
    onDownloadClick,
    onProceedExportClick,
    onTryAgainClick,
    setClose,
    state,
}: ExportModalProps) {
    return (
        <Modal
            modalAriaTitle={downloadLang.DATA_EXPORT}
            isOpen={isOpen}
            handleClose={setClose}
            modalStyles={{ width: '460px' }}
            contentStyles={{ paddingTop: '0' }}
            isShowCloseIcon={isShowCloseIcon}
            testId="dataExportModal"
        >
            {state === 'initial' && (
                <InitialState
                    onLeftClick={onApplyFilterClick}
                    onRightClick={onProceedExportClick}
                />
            )}
            {state === 'progress' && <ProgressState length={length} />}
            {state === 'success' && <SuccessState onRightClick={onDownloadClick} />}
            {state === 'error' && <ErrorState onRightClick={onTryAgainClick} />}
        </Modal>
    );
}
