export const ellipsisText = {
    maxWidth: '10ch',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
};

export const ellipsisTextStart = {
    ...ellipsisText,
    direction: 'rtl',
};
