import { TableCell } from '@mui/material';

import { ColoredAmountText } from '../ColoredAmountText';

type Props = {
    amount: string | undefined | null;
    testId?: string;
    align?: 'left' | 'center' | 'right' | 'justify' | 'inherit';
};

export function AmountCell({ align = 'right', amount, testId = 'amountCell' }: Props) {
    return (
        <TableCell align={align} data-testid={testId}>
            <ColoredAmountText amount={amount} />
        </TableCell>
    );
}
