export default {
    BACK_TO_EDITING: 'Back to edit',
    BULK_CREATION: 'Bulk creation',
    CANCEL: 'Cancel',
    COMPLETED: 'Completed',
    CONFIRM_AND_CREATE: 'Confirm and create',
    COUNTRIES: 'Countries',
    CREATE_NEW: 'Create new',
    CSV: 'CSV',
    DELIMITER: 'Use , as a delimiter',
    DOT_SEPARATOR: '•',
    DRAG_AND_DROP: 'Drag & Drop File or Browse',
    DROP_FILE_HERE: 'Drop the files here',
    DROP_NOT_POSSIBLE: 'Drop not possible. Wrong file format.',
    EDIT: 'Edit',
    GENERATE_LINK: 'Generate activation link',
    INTERNAL_NOTES: 'Internal notes',
    INTERNAL_NOTES_OF_CREATION: 'Internal notes (of creation)',
    LOADING: 'Loading...',
    NO_RESULTS_FOUND: '0 results found',
    NOTES: 'Notes',
    OPTIONAL: '(optional)',
    PLEASE_CHOOSE_CSV_FILE: 'Please choose CSV file to upload.',
    PREVIOUS_STEP: 'Previous step',
    REMOVE: 'Remove',
    VIEW_DETAILS: 'View details',
};
