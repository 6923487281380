import { Box, Typography } from '@mui/material';

import { SecondaryButton } from '../../Buttons';
import { ErrorSvg } from '../../Icons';
import downloadLang from '../../lang/download';
import { ModalContainer } from './ModalContainer';

type ErrorStateProps = {
    onRightClick: () => void;
};

export function ErrorState({ onRightClick }: ErrorStateProps) {
    return (
        <ModalContainer
            RightButton={
                <SecondaryButton onClick={onRightClick} testId="tryAgain">
                    {downloadLang.TRY_AGAIN}
                </SecondaryButton>
            }
            testId="errorState"
        >
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                }}
            >
                <ErrorSvg />
                <Typography variant="h2" sx={{ textAlign: 'center' }}>
                    {downloadLang.ERROR.FAILED}
                </Typography>
                <Typography variant="body1">{downloadLang.ERROR.DESCRIPTION}</Typography>
            </Box>
        </ModalContainer>
    );
}
