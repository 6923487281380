import React from 'react';

import { Modal } from './Modal';

type FilterModalWrapperProps = {
    title: string;
    isOpen: boolean;
    handleClose: () => void;
    children: React.ReactNode;
    testId: string;
};

/**
 * @deprecated
 */
export function FilterModalWrapper({
    children,
    handleClose,
    isOpen,
    testId,
    title,
}: FilterModalWrapperProps) {
    return (
        <Modal
            handleClose={handleClose}
            isOpen={isOpen}
            title={title}
            modalAriaTitle={title}
            modalStyles={{ width: '435px' }}
            contentStyles={{
                display: 'flex',
                flexDirection: 'column',
                gap: '24px',
                paddingTop: '16px',
            }}
            testId={testId}
        >
            {children}
        </Modal>
    );
}
