import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton } from '@mui/material';
import React from 'react';

import { ActionMenuItemProps, MenuList } from './MenuList';

type ActionsMenuProps = {
    menuItems: ActionMenuItemProps[];
};

export function ActionsMenu({ menuItems }: ActionsMenuProps) {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);

    const handleToggle = () => setOpen((prevOpen) => !prevOpen);

    const handleClose = (event: Event | React.SyntheticEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef?.current?.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <>
            <IconButton
                ref={anchorRef}
                id="actions-button"
                data-testid="actionsDotsButton"
                aria-controls={open ? 'actions-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <MoreVertIcon />
            </IconButton>
            <MenuList
                menuItems={menuItems}
                anchorRef={anchorRef}
                isOpen={open}
                onClose={handleClose}
            />
        </>
    );
}
