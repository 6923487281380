import { NavLink as RRDNavLink } from 'react-router-dom';

export type NavLinkProps = {
    to: string;
    label: string | React.ReactNode;
    isDisabled?: boolean;
    textDecoration?: string;
};

export function NavLink({ isDisabled = false, label, textDecoration = 'none', to }: NavLinkProps) {
    return (
        <RRDNavLink
            to={to}
            style={{
                color: 'inherit',
                display: 'flex',
                pointerEvents: isDisabled ? 'none' : 'auto',
                textDecoration,
                width: '100%',
            }}
            onClick={(e) => isDisabled && e.preventDefault()}
        >
            {label}
        </RRDNavLink>
    );
}
