import { Stack, Typography } from '@mui/material';

import { FormActionButtons } from '../Buttons';
import commonLang from '../lang/common';

type Props = {
    children: React.ReactNode;
    onFormSubmit: () => void;
    onBack: () => void;
    isLoading: boolean;
    title?: string;
    backButtonText?: string;
    submitButtonText?: string;
};

export function SummaryStepWrapper({
    backButtonText = commonLang.BACK_TO_EDITING,
    children,
    isLoading,
    onBack,
    onFormSubmit,
    submitButtonText = commonLang.CONFIRM_AND_CREATE,
    title,
}: Props) {
    return (
        <Stack direction="column" data-testid="summaryStep">
            {!!title && (
                <Typography variant="h3" sx={{ marginBottom: '24px' }}>
                    {title}
                </Typography>
            )}
            {children}
            <Stack
                justifyContent="space-between"
                sx={{
                    width: '443px',
                }}
            >
                <FormActionButtons
                    onClick={onFormSubmit}
                    onBackHandler={onBack}
                    backButtonText={backButtonText}
                    submitButtonText={submitButtonText}
                    isLoading={isLoading}
                    isSubmitDisabled={isLoading}
                />
            </Stack>
        </Stack>
    );
}
