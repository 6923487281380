import { Container } from '@mui/material';
import React from 'react';

type PageContainerType = { children: React.ReactNode; testId?: string };

export function PageContainer({ children, testId = 'pageContainer' }: PageContainerType) {
    return (
        <Container
            maxWidth={false}
            sx={(theme) => ({
                maxWidth: ({
                    breakpoints: {
                        values: { xxl2 },
                    },
                }) => xxl2,
                my: 4,
                position: 'relative',
                [theme.breakpoints.up('xl')]: {
                    px: 4,
                },
                [theme.breakpoints.up('xxl')]: {
                    px: 6,
                },
            })}
            data-testid={testId}
        >
            {children}
        </Container>
    );
}
