import { SxProps, Theme, CircularProgress } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import React from 'react';

import type { Sorting } from '@dock/types-common';

import { useTableInfiniteScroll } from '@dock/react-hooks';

import { TableSkeleton } from '../TableSkeleton';
import { CustomErrorRow } from './Error/CustomErrorRow';
import { NotFound } from './Error/NotFound';
import { OneCellInRow } from './Rows/OneCellInRow';
import { Column, TableHeader } from './TableHeader';

export type MinimalRowProps = {
    index?: string;
    id?: string;
};

export type SimpleTableProps<T, RowProps extends MinimalRowProps> = {
    columns: Column[];
    RowComponent: React.FC<RowProps>;
    rows: RowProps[];
    sx?: SxProps<Theme>;
    isLoading?: boolean;
    error?: T;
    isLoadingNextPage?: boolean;
    thStyles?: SxProps;
    onReachingBottom?: () => void;
    onSortClick?: ((property: string) => void) | undefined;
    sorting?: Sorting | undefined;
};

const AMOUNT_OF_ROWS_WITH_SKELETONS = 15;

export function SimpleTable<T, RowProps extends MinimalRowProps>({
    columns,
    error,
    isLoading,
    isLoadingNextPage,
    onReachingBottom,
    onSortClick,
    RowComponent,
    rows,
    sorting,
    sx = {},
    thStyles = {},
}: SimpleTableProps<T, RowProps>) {
    const [tableEl] = useTableInfiniteScroll<HTMLDivElement>(isLoadingNextPage, onReachingBottom);

    return (
        <TableContainer
            ref={tableEl}
            component={Paper}
            sx={{
                borderRadius: 0,
                maxHeight: 700,
                minWidth: 650,
                overflowY: 'auto',
                ...sx,
            }}
        >
            <Table stickyHeader>
                <TableHeader
                    columns={columns}
                    thStyles={thStyles}
                    onSortClick={onSortClick}
                    sorting={sorting}
                />
                <TableBody>
                    {Boolean(error) && (
                        <OneCellInRow testId="tableError">
                            <CustomErrorRow error={error} />
                        </OneCellInRow>
                    )}

                    <TableSkeleton
                        shouldShow={Boolean(isLoading)}
                        rows={AMOUNT_OF_ROWS_WITH_SKELETONS}
                        columns={columns.length}
                    />

                    {rows.length ? (
                        rows.map((row, index) => {
                            const rowKey = String(row.index || row.id || index);
                            return <RowComponent {...row} key={rowKey} />;
                        })
                    ) : (
                        <OneCellInRow sx={{ minHeight: '450px' }}>
                            <NotFound testId="emptyTable" />
                        </OneCellInRow>
                    )}

                    {isLoadingNextPage && (
                        <OneCellInRow>
                            <CircularProgress />
                        </OneCellInRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
