import { SxProps, Theme } from '@mui/material';

import { NavLink } from '../ActionsMenu';
import { CommonButton } from './CommonButton';

type CommonButtonAsLinkProps = {
    label: string;
    to: string;
    isDisabled?: boolean;
    testId?: string;
    icon?: React.ReactNode;
    sx?: SxProps<Theme>;
};

export function CommonLinkAsButton({
    icon,
    isDisabled = false,
    label,
    sx,
    testId = 'commonLinkAsButton',
    to,
}: CommonButtonAsLinkProps) {
    return (
        <NavLink
            label={
                <CommonButton
                    onClick={() => {}}
                    label={label}
                    testId={isDisabled ? 'disabledCommonButton' : testId}
                    icon={icon}
                    sx={sx}
                    isDisabled={isDisabled}
                />
            }
            isDisabled={isDisabled}
            to={to}
        />
    );
}
