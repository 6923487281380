import lang from '../../lang/common';
import tablesLang from '../../lang/tables';
import { MessageTemplate } from './MessageTemplate';

type Props = {
    testId?: string;
};

export function NotFound({ testId = 'notFound' }: Props) {
    return (
        <MessageTemplate
            title={lang.NO_RESULTS_FOUND}
            description={tablesLang.ERROR.TRY_SEARCH_AGAIN}
            testId={testId}
        />
    );
}
