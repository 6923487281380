import {
    Popper,
    List,
    Grow,
    Paper,
    ClickAwayListener,
    Box,
    PopperPlacementType,
    ListItem,
    ListItemText,
} from '@mui/material';

import lang from '../lang/common';

export type ActionMenuItemProps = {
    content: string | React.ReactNode;
    id: string;
    isHidden?: boolean;
};

type Props = {
    isOpen: boolean;
    isLoading?: boolean;
    onClose: (event: Event | React.SyntheticEvent) => void;
    menuItems: ActionMenuItemProps[];
    place?: PopperPlacementType;
    anchorRef: React.RefObject<HTMLButtonElement>;
};

export function MenuList({
    anchorRef,
    isLoading,
    isOpen,
    menuItems,
    onClose,
    place = 'left',
}: Props) {
    const filteredMenuItems = menuItems.filter(({ isHidden = false }) => !isHidden);
    const isNoResults = !isLoading && filteredMenuItems.length === 0;

    return (
        <Popper
            open={isOpen}
            anchorEl={anchorRef.current}
            role={undefined}
            placement={place}
            transition
            disablePortal
            sx={{ zIndex: '2' }}
        >
            {({ placement, TransitionProps }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
                    }}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={onClose}>
                            <List
                                id="actions-menu"
                                aria-labelledby="actions-button"
                                sx={{ maxHeight: '250px', minWidth: '100px', overflowX: 'auto' }}
                            >
                                {isLoading && (
                                    <ListItem data-testid="loadingOption">
                                        <ListItemText primary={lang.LOADING} />
                                    </ListItem>
                                )}
                                {isNoResults && (
                                    <ListItem data-testid="notFoundOption">
                                        <ListItemText primary={lang.NO_RESULTS_FOUND} />
                                    </ListItem>
                                )}
                                {filteredMenuItems.map(({ content, id }, index) => (
                                    <Box key={id} data-testid={`option${index}`}>
                                        {content}
                                    </Box>
                                ))}
                            </List>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    );
}
