import DoneAllIcon from '@mui/icons-material/DoneAll';
import Box from '@mui/material/Box';
import React from 'react';

import { useHover } from '@dock/react-hooks';

import { CopyButton } from './CopyButton';
import { useCopyButton } from './useCopyButton';

export type TextWithCopyIconCellProps = {
    text?: string | null;
    children: React.ReactElement;
};

export function TextWithCopyIcon({ children, text }: TextWithCopyIconCellProps) {
    const { copyHandler, isCopied } = useCopyButton();
    const [ref, isHover] = useHover<HTMLElement>();

    if (!text) {
        return children;
    }

    return (
        <Box sx={{ display: 'flex' }} ref={ref} data-testid="textWithCopyIcon">
            {children}
            <div style={{ paddingRight: '30px', position: 'relative' }}>
                {isHover && !isCopied && <CopyButton text={text} onCopy={copyHandler} />}
                {isCopied && (
                    <div style={{ padding: '0 8px', position: 'absolute' }}>
                        <DoneAllIcon data-testid="doneAllIcon" fontSize="small" color="success" />
                    </div>
                )}
            </div>
        </Box>
    );
}
