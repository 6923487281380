import { Box, SxProps } from '@mui/material';

type Props = {
    children: React.ReactNode;
    customStyles?: SxProps;
};

export function PageActionButtonWrapper({ children, customStyles }: Props) {
    return (
        <Box
            sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: '24px',
                width: '100%',
                ...customStyles,
            }}
        >
            {children}
        </Box>
    );
}
