import { Box, Divider, Theme, Typography } from '@mui/material';
import React from 'react';

export type Tab<TabEnum> = {
    label: string;
    name: TabEnum;
    amount?: number;
    isHidden?: boolean;
};

type TabSwitcherProps<TabEnum> = {
    currentTab: TabEnum;
    setCurrentTab: React.Dispatch<React.SetStateAction<TabEnum>>;
    tabs: Tab<TabEnum>[];
};

const GRID_ITEM_HEIGHT = '33px';
const TYPOGRAPHY_BOTTOM_POSITION = '-12.5px';

export const getTabSwitcherTypographyStyles = (theme: Theme, isCurrentTab: boolean) => ({
    '&::before': {
        backgroundColor: 'primary.main',
        bottom: TYPOGRAPHY_BOTTOM_POSITION,
        content: '""',
        display: isCurrentTab ? 'block' : 'none',
        height: '2px',
        position: 'absolute',
        width: '100%',
    },
    color: isCurrentTab ? theme.palette.primary.main : theme.palette.grey[600],
    margin: '0 auto',
    position: 'relative',
    width: 'fit-content',
});

export const tabSwitcherItemStyles = {
    cursor: 'pointer',
    height: GRID_ITEM_HEIGHT,
    justifyContent: 'start',
    marginRight: '16px',
    padding: '0 24px',
    whiteSpace: 'nowrap',
};

export function TabSwitcher<TabEnum>({
    currentTab,
    setCurrentTab,
    tabs,
}: TabSwitcherProps<TabEnum>) {
    const tabChangeHandler = (tab: TabEnum) => {
        setCurrentTab(tab);
    };

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                {tabs
                    .filter(({ isHidden }) => !isHidden)
                    .map(({ label, name }) => {
                        const isCurrentTab = currentTab === name;

                        return (
                            <Box
                                key={label}
                                onClick={() => tabChangeHandler(name)}
                                sx={tabSwitcherItemStyles}
                                data-testid={name}
                            >
                                <Typography
                                    variant="h4"
                                    sx={(theme) =>
                                        getTabSwitcherTypographyStyles(theme, isCurrentTab)
                                    }
                                >
                                    {label}
                                </Typography>
                            </Box>
                        );
                    })}
            </Box>

            <Divider sx={{ color: 'grey.600' }} />
        </>
    );
}
