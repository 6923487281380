import AddIcon from '@mui/icons-material/Add';
import { CircularProgress, SxProps, Theme } from '@mui/material';
import React from 'react';

import { PrimaryButton } from './PrimaryButton';

type CommonButtonProps = {
    onClick: () => void;
    label: string;
    testId?: string;
    icon?: React.ReactNode;
    isDisabled?: boolean;
    isLoading?: boolean;
    sx?: SxProps<Theme>;
};

export function CommonButton({
    icon = <AddIcon />,
    isDisabled,
    isLoading,
    label,
    onClick,
    sx,
    testId = 'commonButton',
}: CommonButtonProps) {
    return (
        <PrimaryButton
            onClick={onClick}
            startIcon={
                isLoading ? (
                    <CircularProgress data-testid="loadingIcon" color="inherit" size="1.2rem" />
                ) : (
                    icon
                )
            }
            testId={isDisabled ? 'disabledCommonButton' : testId}
            isDisabled={isDisabled || isLoading}
            sx={sx}
        >
            {label}
        </PrimaryButton>
    );
}
