import { Button, SxProps, Theme } from '@mui/material';
import React from 'react';

type SecondaryButtonProps = {
    children: React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    isDisabled?: boolean;
    isLoading?: boolean;
    testId?: string;
    sx?: SxProps<Theme>;
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
    fullWidth?: boolean;
    type?: 'button' | 'submit' | 'reset' | undefined;
    size?: 'large' | 'small' | 'medium';
    disableElevation?: boolean;
};

export function SecondaryButton({
    children,
    disableElevation = false,
    endIcon,
    fullWidth = false,
    isDisabled = false,
    isLoading = false,
    onClick,
    size = 'small',
    startIcon,
    sx = {},
    testId = 'secondaryButton',
    type = 'button',
}: SecondaryButtonProps) {
    return (
        <Button
            type={type}
            onClick={onClick}
            disabled={isDisabled}
            startIcon={startIcon}
            // TODO: isLoading should be removed here (but not possible because of breaking changes)
            endIcon={isLoading && endIcon}
            fullWidth={fullWidth}
            data-testid={testId}
            size={size}
            disableElevation={disableElevation}
            sx={{
                '&:hover': {
                    backgroundColor: 'primary.light',
                },
                backgroundColor: isDisabled ? 'grey.500' : 'secondary.light',
                color: 'primary.dark',
                ...sx,
            }}
        >
            {children}
        </Button>
    );
}
