import { Collapse } from '@mui/material';
import React from 'react';

import { HeadlessTable } from '../SimpleTable';
import { TableSkeleton } from '../TableSkeleton';
import { CollapsableTitle, DetailsTitle } from '../Typography/CollapsableTitle';

type CollapsableElementType = {
    isCollapsed: boolean;
    isLoading: boolean;
    title: string;
    flip: () => void;
    children?: React.ReactNode;
    columns: number;
};

export function CollapsableElement({
    children,
    columns,
    flip,
    isCollapsed,
    isLoading,
    title,
}: CollapsableElementType) {
    return (
        <>
            <CollapsableTitle
                isOpen={isCollapsed}
                titleElement={<DetailsTitle title={title} />}
                handleCollapsableElement={flip}
            />
            <Collapse in={isCollapsed} timeout="auto" unmountOnExit>
                <HeadlessTable>
                    <TableSkeleton rows={columns} columns={2} shouldShow={isLoading} />
                    {!isLoading && children}
                </HeadlessTable>
            </Collapse>
        </>
    );
}
