import { useEffect, useState } from 'react';

import { useModal } from '@dock/react-hooks';

import { ModalState } from './components/ExportModal';
import { DownloadParams } from './Download';
import { CSVDataFormat, FetchNextPage } from './types';
import { downloadCsv } from './utils/downloadCsv';

export type UseExportModalParams<TNext, TData> = {
    hasNextPage: boolean | undefined;
    fetchNextPage: FetchNextPage<TNext>;
    isError: boolean;
    isFetching: boolean;
    data: TData;
    mapper: (value: TData) => CSVDataFormat;
    onApplyFilterClick?: () => void;
    emptyData: TData;
    fileNamePrefix: string;
};

const hasNextPagePropertyExists = (obj: unknown): obj is { hasNextPage: boolean } =>
    typeof obj === 'object' && obj !== null && 'hasNextPage' in obj;

export function useExportModal<TNext, TData extends unknown[]>({
    data,
    emptyData,
    fetchNextPage,
    fileNamePrefix,
    hasNextPage,
    isError,
    isFetching,
    mapper,
    onApplyFilterClick,
}: UseExportModalParams<TNext, TData>): DownloadParams {
    const [state, setState] = useState<ModalState>('initial');
    const { isOpen, setClose, setOpen } = useModal();

    const isShowCloseIcon = state === 'initial' || state === 'success' || state === 'error';
    const hasExportFinished = !!data.length && hasNextPage === false;
    const mappedData = mapper(data.length ? data : emptyData);

    const handleExport = async () => {
        const fetchNextPageRecursively = async () => {
            const res = await fetchNextPage();
            if (hasNextPagePropertyExists(res) && res.hasNextPage) {
                await fetchNextPageRecursively();
            }
        };

        if (hasNextPage) {
            await fetchNextPageRecursively();
        }
    };

    const handleDownload = () => {
        if (!isFetching) {
            downloadCsv({
                data: mappedData,
                date: new Date(),
                prefix: fileNamePrefix,
            });
        }
    };

    const handleProceedExport = async () => {
        if (!hasNextPage) {
            setState('success');
            return;
        }
        setState('progress');
        await handleExport();
    };

    const handleTryAgainClick = async () => {
        await handleProceedExport();
    };

    const handleDownloadClick = () => {
        handleDownload();
        setClose();
    };

    const handleApplyFilters = () => {
        if (onApplyFilterClick) {
            onApplyFilterClick();
        }
        setClose();
    };

    useEffect(() => {
        if (hasExportFinished) {
            setState('success');
        }

        if (!hasExportFinished) {
            setState('initial');
        }
    }, [hasExportFinished]);

    useEffect(() => {
        if (isError) {
            setState('error');
        }
    }, [isError]);

    return {
        isOpen,
        isShowCloseIcon,
        length: mappedData.length,
        onApplyFilterClick: handleApplyFilters,
        onDownloadClick: handleDownloadClick,
        onProceedExportClick: handleProceedExport,
        onTryAgainClick: handleTryAgainClick,
        setClose,
        setOpen,
        state,
    };
}
