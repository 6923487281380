import { TableRow, TableCell, Collapse, Table, TableBody } from '@mui/material';
import React from 'react';

import type { Column } from '../SimpleTable';

import { CollapsableElementHeader } from './CollapsableElementHeader';

interface CollapsableTableElementProps {
    isOpen: boolean;
    columns: Column[] | string[] | [];
    RowComponent: React.ReactNode;
}

export function CollapsableTableElement({
    columns,
    isOpen,
    RowComponent,
}: CollapsableTableElementProps) {
    return (
        <TableRow>
            <TableCell
                sx={{
                    backgroundColor: ({ palette }) => palette.grey[300],
                    p: 0,
                }}
                colSpan={12}
            >
                <Collapse in={isOpen} timeout="auto" unmountOnExit>
                    <Table>
                        <CollapsableElementHeader columns={columns} />

                        <TableBody>{RowComponent}</TableBody>
                    </Table>
                </Collapse>
            </TableCell>
        </TableRow>
    );
}
