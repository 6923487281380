import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import {
    InputAdornment,
    IconButton,
    FormControl,
    InputLabel,
    OutlinedInput,
    FormHelperText,
} from '@mui/material';
import React from 'react';

type PasswordFieldProps = {
    value: string;
    label: string;
    onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
    autoComplete?: 'current-password' | 'new-password';
    isError?: boolean;
    fullWidth?: boolean;
    autoFocus?: boolean;
    helpText?: string;
    readOnly?: boolean;
};

export function PasswordField({
    autoComplete = 'current-password',
    autoFocus = true,
    fullWidth = true,
    helpText = '',
    isError = false,
    label,
    onChange,
    readOnly = false,
    value,
}: PasswordFieldProps) {
    const [isShowPassword, setIsShowPassword] = React.useState<boolean>(false);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleClickShowPassword = () => {
        setIsShowPassword((prevState) => !prevState);
    };

    return (
        <FormControl fullWidth data-testid="passwordForm">
            <InputLabel htmlFor="password" error={isError} variant="outlined">
                {label}
            </InputLabel>
            <OutlinedInput
                autoComplete={autoComplete}
                readOnly={readOnly}
                value={value}
                label={label}
                onChange={onChange}
                fullWidth={fullWidth}
                autoFocus={autoFocus}
                error={isError}
                id="password"
                type={isShowPassword ? 'text' : 'password'}
                inputProps={{
                    'data-testid': 'passwordField',
                }}
                endAdornment={
                    <InputAdornment position="end" sx={{ position: 'relative' }}>
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            data-testid="showPasswordButton"
                        >
                            {isShowPassword ? (
                                <VisibilityOffOutlinedIcon />
                            ) : (
                                <VisibilityOutlinedIcon />
                            )}
                        </IconButton>
                    </InputAdornment>
                }
            />
            {!!helpText && (
                <FormHelperText
                    data-testid="helperText"
                    error={isError}
                    sx={{ position: 'absolute', top: '100%' }}
                >
                    {helpText}
                </FormHelperText>
            )}
        </FormControl>
    );
}
