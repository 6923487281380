import { SxProps, Typography, Stack, Skeleton, TypographyVariant } from '@mui/material';
import React from 'react';

type ContentTitleProps = {
    title: string;
    content: string | React.ReactNode | undefined;
    titleStyles?: SxProps;
    contentStyles?: SxProps;
    contentVariant?: TypographyVariant;
    isLoading?: boolean;
    contentTestId?: string;
};

export function ContentTitle({
    content,
    contentStyles = {},
    contentTestId = undefined,
    contentVariant = 'h3',
    isLoading = false,
    title,
    titleStyles = {},
}: ContentTitleProps) {
    return (
        <>
            <Typography
                color="grey.600"
                variant="subtitle1"
                sx={{
                    flex: 1,
                    fontWeight: 'regular',
                    mb: '4px',
                    ...titleStyles,
                }}
            >
                {title}
            </Typography>

            {isLoading ? (
                <Skeleton
                    animation="wave"
                    aria-label="progressbar"
                    data-testid="contentTitleSkeleton"
                />
            ) : (
                <Typography
                    variant={contentVariant}
                    sx={{ ...contentStyles }}
                    data-testid={contentTestId}
                >
                    {content}
                </Typography>
            )}
        </>
    );
}

export function DetailsTitle({ title }: { title: string }) {
    return (
        <Stack
            sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <Typography variant="h3">{title}</Typography>
        </Stack>
    );
}
