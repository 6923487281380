import { Box, Typography } from '@mui/material';

type Props = {
    title: string;
    description?: string;
    testId?: string;
};

export function MessageTemplate({ description, testId, title }: Props) {
    return (
        <Box sx={{ textAlign: 'center' }} data-testid={testId}>
            <Typography>{title}</Typography>
            {description && <Typography>{description}</Typography>}
        </Box>
    );
}
