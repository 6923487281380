import { useState } from 'react';

import { useCopyToClipboard } from '@dock/react-hooks';

export function useCopyButton() {
    const [isCopied, setIsCopied] = useState(false);
    const [, copy] = useCopyToClipboard();

    const copyHandler = async (str: string) => {
        await copy(str);
        setIsCopied(true);

        setTimeout(() => {
            setIsCopied(false);
        }, 500);
    };

    return { copyHandler, isCopied };
}
