import { createContext } from 'react';

import { UseProvideNotificationsType } from '@dock/react-hooks';

const defaultValue: UseProvideNotificationsType = {
    closeNotification: () => {},
    isOpen: false,
    notification: { description: '', title: '', type: null },
    showErrorNotification: () => {},
    showSuccessNotification: () => {},
};

export const alertNotificationsContext = createContext<UseProvideNotificationsType>(defaultValue);
