import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import {
    Toolbar,
    Typography,
    AppBar as MuiAppBar,
    Button,
    Divider,
    Menu,
    MenuItem,
    Box,
    styled,
    SvgIconTypeMap,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import React from 'react';

import { useAnchor } from '@dock/react-hooks';

import { Skeleton } from '../Skeleton';

type AppBarMapperProps = {
    navItems: { component: React.ReactElement; id: string }[];
    dropdownTitle?: React.ReactElement;
    dateUTC?: React.ReactElement;
    breadcrumbs?: React.ReactElement;
    user: string;
    version?: string;
    IconComponent?: OverridableComponent<SvgIconTypeMap<unknown, 'svg'>>;
    isLoading?: boolean;
};

const AppBar = styled(MuiAppBar)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    boxShadow: `0px 7px 10px 0px ${theme.palette.grey[700]}0A`,
}));

export function AppBarMapper({
    breadcrumbs,
    dateUTC,
    dropdownTitle,
    IconComponent = AccountCircleOutlinedIcon,
    isLoading = false,
    navItems,
    user,
    version,
}: AppBarMapperProps) {
    const { anchorEl, handleClick, handleClose, isOpen } = useAnchor();
    const expandIcon = isOpen ? (
        <ExpandLessOutlinedIcon
            sx={{
                color: ({ palette }) => palette.grey[400],
                minWidth: '26px',
                ml: 3,
            }}
        />
    ) : (
        <ExpandMoreOutlinedIcon
            sx={{
                color: ({ palette }) => palette.grey[400],
                minWidth: '26px',
                ml: 3,
            }}
        />
    );

    return (
        <AppBar position="relative">
            <Toolbar
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '22px',
                }}
            >
                {breadcrumbs}
                <Box sx={{ alignItems: 'center', display: 'flex', gap: '24px' }}>
                    {dateUTC}
                    <Box>
                        {isLoading ? (
                            <Skeleton height={36} width={250} />
                        ) : (
                            <Button
                                id="user-button"
                                data-testid="userMenuButton"
                                aria-controls={isOpen ? 'user-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={isOpen ? 'true' : undefined}
                                onClick={handleClick}
                                sx={{
                                    color: ({ palette }) => palette.grey[800],
                                    padding: '6px 8px',
                                }}
                            >
                                <IconComponent
                                    sx={{
                                        color: 'primary.main',
                                        minWidth: '40px',
                                    }}
                                />
                                {user}
                                {expandIcon}
                            </Button>
                        )}

                        <Menu
                            id="user-menu"
                            anchorEl={anchorEl}
                            open={isOpen}
                            onClose={handleClose}
                            MenuListProps={{ 'aria-labelledby': 'user-button' }}
                            sx={{ position: 'absolute' }}
                            disableScrollLock
                        >
                            <Box sx={{ maxWidth: '100%', minWidth: 300 }}>
                                {version && (
                                    <>
                                        <Box sx={{ padding: '0 16px 8px 16px' }}>
                                            <Typography
                                                variant="body2"
                                                color="primary.main"
                                                textAlign="right"
                                            >
                                                {version}
                                            </Typography>
                                        </Box>
                                        <Divider />
                                    </>
                                )}
                                {dropdownTitle}
                                {navItems.map((el) => (
                                    <Box key={el.id}>
                                        <Divider sx={{ margin: '8px 0' }} />
                                        <MenuItem dense sx={{ padding: 0 }}>
                                            {el.component}
                                        </MenuItem>
                                    </Box>
                                ))}
                            </Box>
                        </Menu>
                    </Box>
                </Box>
            </Toolbar>
        </AppBar>
    );
}
