import { Box } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { isEqual, differenceInDays } from 'date-fns';
import React from 'react';

import { getDateFromDaysAgo } from '@dock/common';
import { transformDate } from '@dock/react-util';
import { DateFilterParams } from '@dock/types-common';

import { DateRangePicker } from './DateRangePicker';
import lang from './lang';
import { getDateRangeLabel } from './utilities';

type DateRangeDropDownProps = {
    isSearching?: boolean | undefined;
    handleFilters: (dateObj: DateFilterParams) => void;
    from: Date | undefined;
    to: Date | undefined;
};

const NOT_DEFINED_DATE = -1;
const CUSTOM_DATE = 0;

export const dateRanges = [
    { days: NOT_DEFINED_DATE, label: lang.ALL_TIME_DATE },
    { days: 1, label: lang.DROP_DOWN_LAST_24HOURS },
    { days: 7, label: lang.DROP_DOWN_LAST_WEEK },
    { days: 14, label: lang.DROP_DOWN_LAST_2WEEKS },
    { days: 30, label: lang.DROP_DOWN_LAST_MONTH },
    { days: 90, label: lang.DROP_DOWN_LAST_3MONTHS },
    { days: 180, label: lang.DROP_DOWN_LAST_6MONTHS },
];

export function DateRangeDropDownRaw({
    from,
    handleFilters,
    isSearching = false,
    to,
}: DateRangeDropDownProps) {
    const daysInBetween = from ? differenceInDays(getDateFromDaysAgo(0), from) : undefined;
    const [daysAgo, setDaysAgo] = React.useState<number>(
        from
            ? dateRanges.find(({ days }) => daysInBetween === days)?.days ?? CUSTOM_DATE
            : NOT_DEFINED_DATE
    );
    const [isSelectOpen, setSelectOpen] = React.useState<boolean>(false);
    const [isCustomDateDisabled, setCustomDateDisabled] = React.useState<boolean>(!!daysAgo);

    const handleChange = (event: React.SyntheticEvent) => {
        const { dataset } = event.currentTarget as HTMLInputElement;
        const { value } = dataset;
        if (value === undefined) {
            // click without selecting a date range
            setSelectOpen(false);
            setCustomDateDisabled(true);
            return;
        }
        if (value !== '0') {
            // click to a preset date
            setDaysAgo(parseInt(value, 10));
            setCustomDateDisabled(true);
            setSelectOpen(false);
        } else {
            // click for custom range
            setCustomDateDisabled(false);
        }
    };

    const handleCustomRanges = (arg: DateFilterParams) => {
        handleFilters(arg);
        setDaysAgo(0);
        setSelectOpen(false);
    };

    React.useEffect(() => {
        const isDate = daysAgo > NOT_DEFINED_DATE;
        const startingDate = isDate ? getDateFromDaysAgo(daysAgo) : undefined;

        if (daysAgo !== 0 && !isEqual(from ?? NOT_DEFINED_DATE, startingDate ?? NOT_DEFINED_DATE)) {
            handleFilters({
                from: isDate ? startingDate?.toISOString() : '',
                to: '',
            });
        }
    }, [daysAgo]);

    return (
        <Box data-testid="dateRangeDropDown" sx={{ minWidth: '270px' }}>
            <FormControl fullWidth disabled={isSearching}>
                <InputLabel id="dateRangeDropDown">{getDateRangeLabel(daysAgo)}</InputLabel>
                <Select
                    labelId="dateRangeDropDown"
                    value={daysAgo}
                    label={getDateRangeLabel(daysAgo)}
                    inputProps={{
                        ...(!daysAgo && {
                            sx: { padding: '9px 32px 0px 14px' },
                        }),
                    }}
                    onOpen={() => setSelectOpen(true)}
                    onClose={handleChange}
                    open={isSelectOpen}
                >
                    {dateRanges.map((date) => (
                        <MenuItem
                            key={date.days}
                            data-testid={`option${date.days}`}
                            value={date.days}
                        >
                            {date.label}
                        </MenuItem>
                    ))}
                    <MenuItem
                        data-testid="customDateRangeOption"
                        value={CUSTOM_DATE}
                        sx={{
                            justifyContent: 'space-between',
                            ...(!daysAgo && {
                                background: ({ palette }) => palette.grey[100],
                            }),
                        }}
                    >
                        {lang.DROP_DOWN_CUSTOM_DATE_RANGE}
                        {!daysAgo && from && to && (
                            <Box
                                sx={(theme) => ({
                                    fontSize: 10,

                                    [theme.breakpoints.up('xl')]: {
                                        fontSize: 12,
                                    },
                                })}
                            >
                                {`${transformDate(from)} - ${transformDate(to)}`}
                            </Box>
                        )}
                    </MenuItem>
                    <Box padding={2}>
                        <DateRangePicker
                            isDisabled={isCustomDateDisabled}
                            onChange={handleCustomRanges}
                            from={from}
                            to={to}
                            withManualTrigger
                        />
                    </Box>
                </Select>
            </FormControl>
        </Box>
    );
}

export const DateRangeDropDown = React.memo(
    DateRangeDropDownRaw,
    (prev: DateRangeDropDownProps, next: DateRangeDropDownProps) =>
        prev.from === next.from && prev.to === next.to
);
